import api from "!../../node_modules/.pnpm/style-loader@2.0.0_webpack@5.94.0_esbuild@0.18.6_/node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
            import content from "!!../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.94.0_esbuild@0.18.6_/node_modules/css-loader/dist/cjs.js!../../node_modules/.pnpm/postcss-loader@7.3.4_postcss@8.4.44_typescript@5.4.5_webpack@5.94.0/node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[4].use[2]!./style.css";

var options = {};

options.insert = "head";
options.singleton = false;

var update = api(content, options);



export default content.locals || {};